<template>
  <v-dialog v-model="newDialog" persistent max-width="700">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>new professional background</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              label="current Work"
              v-model="view.currentWork"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              label="specialization"
              v-model="view.specialization"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              label="experience"
              v-model="view.experience"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field dense label="gain From" v-model="view.gainFrom">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  v-model="view.start"
                  label="Date Started"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :max="today"
                v-model="view.start"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  v-model="view.end"
                  label="Date ended"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :max="today"
                v-model="view.end"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-card outlined>
          <v-card-title>
            A recognised Professional to endorse this Professional Data
          </v-card-title>
          <v-card-subtitle
            style="
              padding-top: 10px;
              font-size: 0.8em;
              text-transform: lowercase;
            "
          >
            <span style="text-transform: capitalize">W</span>e will send an
            email and an SMS alert to this contact asking the person to endorse
            this data
          </v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  label="contact"
                  v-model="view.contact"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  dense
                  label="contact Phone"
                  placeholder="000 0000000"
                  v-model="view.contactPhone"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              dense
              label="contact email"
              v-model="view.contact_email"
            >
            </v-text-field>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  decimal,
  email,
} from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  props: {
    newDialog: Boolean,
  },
  // mixins: [Utils],
  data() {
    return {
      loading: false,
      responseMessage: null,
      specialtyList: [],
      specialDetList: [],
      menu1: false,
      menu2: false,
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      view: {
        currentWork: null,
        specialization: null,
        experience: null,
        gainFrom: null,
        start: null,
        end: null,
        contact: null,
        contactPhone: null,
        contact_email: null,
      },
    };
  },
  validations: {
    view: {
      currentWork: {
        required,
      },
      specialization: {
        required,
      },
      experience: {
        required,
      },
      gainFrom: {
        required,
      },
      start: {
        required,
      },
      end: {
        required,
      },
      contact: {
        required,
      },
      contactPhone: {
        required,
        decimal,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
      contact_email: {
        required,
        email,
      },
    },
  },
  created() {
    this.specialty();
  },
  watch: {
    "view.specializationid": function (v) {
      console.log(v);
      if (v > 0) {
        this.specialDetail(v);
      }
    },
  },
  methods: {
    specialty() {
      this.loading = true;
      let self = this;
      Restful.general.specialty
        .list()
        .then((response) => {
          console.log(response.data);
          this.specialtyList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    specialDetail(i) {
      this.loading = true;
      let self = this;
      Restful.general.specialtyDetails
        .list(i)
        .then((response) => {
          console.log(response.data);
          this.specialDetList = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeNew", payload);
    },
    submit() {
      let currentWork = this.view.currentWork;
      let specialization = this.view.specialization;
      let experience = this.view.experience;
      let gainFrom = this.view.gainFrom;
      let start = Date.parse(this.view.start);
      let end = Date.parse(this.view.end);
      let contact = this.view.contact;
      let contactPhone = this.view.contactPhone;
      let contact_email = this.view.contact_email;
      let data = {
        currentWork,
        specialization,
        experience,
        gainFrom,
        start,
        end,
        contact,
        contactPhone,
        contact_email,
      };
      console.log("data:", data);
      let payload = { state: false, data: data };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
