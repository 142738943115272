<template>
  <v-dialog v-model="editDialog" persistent max-width="700">
    <v-card :loading="loading" loader-height="2">
      <v-card-title>new professional background</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              label="current Work"
              v-model="view.currentWork"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              label="specialization"
              v-model="view.specialization"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              label="experience"
              v-model="view.experience"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field dense label="gain From" v-model="view.gainFrom">
            </v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="menu1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  v-model="view.start"
                  label="Date Started"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :max="today"
                v-model="view.start"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  v-model="view.end"
                  label="Date ended"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                :max="today"
                v-model="view.end"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-text-field label="layout" v-model="view.layout"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.view.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
export default {
  mixins: [validationMixin],
  props: {
    editDialog: Boolean,
    myId: { type: Number },
    name: { type: String },
  },
  // mixins: [Utils],
  data() {
    return {
      loading: false,
      responseMessage: null,
      specialtyList: [],
      specialDetList: [],
      menu1: false,
      menu2: false,
      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      view: {
        currentWork: null,
        specialization: null,
        experience: null,
        gainFrom: null,
        start: null,
        end: null,
        layout: null,
      },
    };
  },
  validations: {
    view: {
      currentWork: {
        required,
      },
      specialization: {
        required,
      },
      experience: {
        required,
      },
      gainFrom: {
        required,
      },
      start: {
        required,
      },
      end: {
        required,
      },
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers.background
        .find(this.myId)
        .then((response) => {
          console.log(response.data);
          this.view.currentWork = response.data.current_work;
          this.view.specialization = response.data.specialization;
          this.view.experience = response.data.experience;
          this.view.gainFrom = response.data.gained_from;
          this.view.start = moment(response.data.started).format("yyyy-MM-DD");
          this.view.end = moment(response.data.ended).format("yyyy-MM-DD");
          console.log(this.view.start);
          this.view.layout = response.data.row_layout;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeEdit", payload);
    },
    submit() {
      let currentWork = this.view.currentWork;
      let start = Date.parse(this.view.start);
      let end = Date.parse(this.view.end);
      let experience = this.view.experience;
      let gainFrom = this.view.gainFrom;
      let specialization = this.view.specialization;
      let layout = this.view.layout;

      let data = {
        currentWork,
        specialization,
        experience,
        gainFrom,
        start,
        end,
        layout,
      };
      console.log("data:", data);
      let payload = { id: this.myId, state: false, data: data };
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
